import React, { useState } from "react";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CategoryIcon from '@mui/icons-material/Category';
import AppsIcon from '@mui/icons-material/Apps';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FormatListNumberedRtlOutlinedIcon from '@mui/icons-material/FormatListNumberedRtlOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { ROLE } from "app/rkt_query/SettingsReducer";
import useCheckRoles from "@jumbo/dz-components/custom-hooks/useCheckRoles";
import { decryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { Config } from "constant";
import { selectedUserLoginSelector } from "app/rkt_query/SelectedStuffReducer";
import { useSelector } from "react-redux";
import RoomServiceIcon from '@mui/icons-material/RoomService';
import KayakingIcon from '@mui/icons-material/Kayaking';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import PublicIcon from '@mui/icons-material/Public';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CollectionsIcon from '@mui/icons-material/Collections';
import PagesIcon from '@mui/icons-material/Pages';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ChecklistIcon from '@mui/icons-material/Checklist';
import HvacIcon from '@mui/icons-material/Hvac';
import RateReviewIcon from '@mui/icons-material/RateReview';


  





const menus = [
    
   

  {
        label: 'modules',
        type: "section",
        children: [
        
            {
                uri: "/service",
                label: 'Services',
                type: "nav-item",
                visible: true,
                icon: <RoomServiceIcon sx={{ fontSize: 20 }} />
            },
           
            {
                uri: "/contact",
                label: 'Contacts',
                type: "nav-item",
                visible: true,
                icon: <RoomServiceIcon sx={{ fontSize: 20 }} />
            },
           
          

        ]

    },
 {

        label: 'authPages',
        type: "section",
        children: [
           
       
            {
                uri: "/admins",
                label: 'Admin',
                type: "nav-item",
                visible: true,
                icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />,
                is_super:true
            },
            // {
            //     uri:"/users",
            //     label: 'User',
            //     type: "nav-item",
            //     visible: true,
            //     icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />
            // },
        ]

    },

];



export default menus;



    