import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import {
  selectedContactSelector,
  setSelectedContact,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddContactMutation,
  useGetContactQuery,
  useUpdateContactMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import DZRadioGroup from "@jumbo/dz-components/radio-button/DzRadioGroup";

const AddUpdateContact = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedContact = useSelector(selectedContactSelector);

  const { data: res_get } = useGetContactQuery(selectedContact?.id);

  const [updateContact, responseUpdate] = useUpdateContactMutation();
  const [addContact, responseAdd] = useAddContactMutation();
  const bluePrint = BLUE_PRINTS.service;
  const [serviceState, setContactState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();

  const addUpdateContactData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.service?.id);
    formData.append("name", serviceState.name);
    formData.append("title", serviceState.title);
    formData.append("description", serviceState.description);

    const res =
      action === "add"
        ? await addContact(formData)
        : await updateContact(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setContactState(selectedContact ? res_get?.data?.service : bluePrint);
  }, [res_get]);

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {serviceState ? (
        <div style={addUpdateDivStyleGlobal}>
          <DZTextValidation
            error={error?.name}
            label="Name"
            variant="outlined"
            value={serviceState?.name}
            onChange={(e) => {
              setContactState((x) => ({ ...x, name: e.target.value }));
            }}
            placeholder="Name"
          />

          <DZTextValidation
            error={error?.title}
            label="Title"
            variant="outlined"
            value={serviceState?.title}
            onChange={(e) => {
              setContactState((x) => ({ ...x, title: e.target.value }));
            }}
            placeholder="Description"
          />

          <DZTextValidation
            error={error?.description}
            label="Description"
            variant="outlined"
            value={serviceState?.description}
            onChange={(e) => {
              setContactState((x) => ({ ...x, description: e.target.value }));
            }}
            placeholder="Description"
          />

          <DZTextValidation
            error={error.overview}
            label="Overview"
            variant="outlined"
            value={serviceState?.overview}
            onChange={(e) => {setContactState((x) => ({ ...x, overview: e.target.value }))}}
            placeholder="Overview"
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedContact(null));
              }}
            >
              Cancel
            </Button>
            {selectedContact?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateContactData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateContactData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedContact(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateContact;
