import { selectedContactSelector } from 'app/rkt_query/SelectedStuffReducer';
import {  useGetContactQuery } from 'app/rkt_query/storeApis';
import React from 'react'
import { useSelector } from 'react-redux';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewContact = () => {

    const selectedContact = useSelector(selectedContactSelector);
    const { data: res_get } = useGetContactQuery(selectedContact?.id);
    const projectArray =
        [
            { label: 'User Name', data: res_get?.data?.project?.user?.name },
            { label: 'User Email', data: res_get?.data?.project?.user?.email },
            { label: 'Contact', data: res_get?.data?.project?.name },
        ]

    return (
        <div>

            {
                  selectedContact?.id ?
                        <DZGenericView obj={projectArray} closeBtn={true}  /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewContact

