import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllContactsQuery, BLUE_PRINTS, useGetContactQuery, useDeleteContactMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Add from '.';
import { setSelectedContact } from 'app/rkt_query/SelectedStuffReducer';
import ViewContact from './ViewContact';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import AddUpdateContact from './AddUpdateContact';

const ContactList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteContact, responseDelete] = useDeleteContactMutation();
  const { data: res_get_all, isLoading: isLoadingContacts, error: errorContacts } = useGetAllContactsQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.service
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedContact(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedContact(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedContact(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedContact(row)); }
  bluePrint.handleDelete = (id) => { deleteContact(id); }

  


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={"Contacts"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="Contacts"  showLanguageSwitcher={false}  data={res_get_all?.data?.contacts} bluePrint={bluePrint} ViewModel={() => <ViewContact />} ModalContent={() => <AddUpdateContact onClose={hideDialog} />} />
         
        </div>
      </JumboDemoCard>
    </>
  )
}


export default ContactList