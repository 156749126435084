import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllServicesQuery, BLUE_PRINTS, useGetServiceQuery, useDeleteServiceMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Add from '.';
import { setSelectedService } from 'app/rkt_query/SelectedStuffReducer';
import ViewService from './ViewService';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import AddUpdateService from './AddUpdateService';

const ServiceList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteService, responseDelete] = useDeleteServiceMutation();
  const { data: res_get_all, isLoading: isLoadingServices, error: errorServices } = useGetAllServicesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.service
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedService(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedService(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedService(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedService(row)); }
  bluePrint.handleDelete = (id) => { deleteService(id); }

  


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title={"Services"}
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable showAddButton={true} btnTitleAdd="Services"  showLanguageSwitcher={true}  data={res_get_all?.data?.services} bluePrint={bluePrint} ViewModel={() => <ViewService />} ModalContent={() => <AddUpdateService onClose={hideDialog} />} />
         
        </div>
      </JumboDemoCard>
    </>
  )
}


export default ServiceList